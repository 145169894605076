<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box text-center pt-2">
        <span class="mb-0 font-size-22 font-weight-bold">
          <i
            :class="icon"
            v-if="icon.length > 0"></i>
          {{ title }} <span style="text-transform: capitalize;color: #67C23A;font-size: 14px">{{ subTitle }}<sup style="color: #495057;font-size: 12px"></sup></span>
        </span>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
