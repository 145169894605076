<script>
import {  common } from '@/mixins/common';
import { PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'FormIpWhitelistManage',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
        form: {
            id: 0,
            name: '',
            description: '',
            additionalDescription: ''
        },
        rules: {
            name: [
                { required: true, message: ' ', trigger: 'change' }
            ]
        }
    }
  },
  mixins: [
    common
  ],
  methods: {
    submitForm () {
        let _vm = this
        this.$refs.formOwnerManager.validate((valid) => {
            // eslint-disable-next-line
            console.log(valid)
            if (valid) {
                PostApiWithAuthorizationAction("admin/product-modes/save-data/", _vm.form).then(() => {
                    location.reload()
                });
            }
        });
    }
  },
  computed: {},
  mounted () {
    this.form = {
        ... this.data
    }
  },
  watch: {
    data: function(newValue) {
        let _vm = this
        _vm.form = {
            ... newValue
        }
        //
        if (_vm.$refs.drawerPrimaryField) {
          _vm.$refs.drawerPrimaryField.focus()
        }
    }
  }
}
</script>
<template>
    <div class="demo-drawer__content">
        <el-form
            :model="form"
            ref="formOwnerManager"
            @submit.native.prevent="submitForm">
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <el-form-item
                        class="mb-0"
                        :rules="rules.name"
                        prop="name"
                        label="Name">
                        <el-input
                            v-model="form.name"
                            placeholder=""
                            ref="drawerPrimaryField"
                            ></el-input>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <el-form-item class="mb-0" label="Description">
                        <el-input
                            v-model="form.description"
                            type="textarea"
                            placeholder="Description"
                            :rows="4"
                            ></el-input>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <el-form-item class="mb-0" label="Additional Description">
                        <el-input
                            v-model="form.additionalDescription"
                            type="textarea"
                            placeholder="Additional Description"
                            :rows="4"
                            ></el-input>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mx-0 text-right">
                <b-col cols="12">
                    <el-button
                        type="success"
                        native-type="submit">
                        <i class="fas fa-save"></i> Save Changes
                    </el-button>
                </b-col>
            </b-row>
        </el-form>
    </div>
</template>

<style>
    .el-form-item__label {
        line-height: 14px;
    }
</style>