<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'SettingShippingPage',
  components: {},
  props: {},
  data() {
    return {
      form: {
        companyName: '',
        companyAddress: '',
        companyAddress2: '',
        companyCity: '',
        companyState: '',
        companyCountry: '',
        companyPhone: '',
        companyPostalCode: '',
        packageWeight: 2,
        handlingTime: 1,
        appId: 1
      },
      applications: []
    }
  },
  mixins: [
    common
  ],
  methods: {
    loadApplications() {
        let _vm = this
        return GetApiActionWithAuthorization("admin/common/get-applications").then(res => {
            _vm.applications = res.data
        });
    },
    onSubmit() {
        let _vm = this
        return PostApiWithAuthorizationAction("admin/setting/shipping/save-page-data/" + _vm.form.appId, _vm.form).then(() => {
            _vm.$toast.success("Changes saved successfully.", {
                position: "top-right",
                timeout: 1000,
                closeOnClick: true
            });
        });        
    },
    loadPageData() {
        let _vm = this
        return GetApiActionWithAuthorization("admin/setting/shipping/get-page-data/" + _vm.form.appId).then(res => {
            const { 
                companyName,
                companyAddress,
                companyAddress2,
                companyCity,
                companyState,
                companyCountry,
                companyPhone,
                companyPostalCode,
                packageWeight,
                handlingTime,
                appId 
            } = res.data
            _vm.form = {
                companyName,
                companyAddress,
                companyAddress2,
                companyCity,
                companyState,
                companyCountry,
                companyPhone,
                companyPostalCode,
                packageWeight,
                handlingTime,
                appId
            }
        });
    }
  },
  computed: {},
  mounted () {
    this.loadApplications().then(() => {
        this.loadPageData()
    })
  },
  watch: {}
}
</script>
<template>
    <div class="setting-shipping__content">
        <el-form ref="form" :model="form" label-width="180px" @submit.prevent.native="onSubmit()">
            <b-row class="mx-0">
                <b-col cols="4">
                    <div class="box-section mb-3 pb-1">
                        <div class="box-section-header">APPLICATION</div>
                        <div class="box-section-body py-2">
                            <el-select
                                v-model="form.appId"
                                @change="loadPageData()"
                                placeholder="Choose Application"
                                size="medium"
                                style="width: 100%"
                                >
                                <el-option
                                    v-for="(ebayApp, index) in applications"
                                    :key="index"
                                    :label="ebayApp.name"
                                    :value="ebayApp.id"
                                ></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="box-section mb-3 pb-1">
                        <div class="box-section-header">SHIPMENT DETAIL</div>
                        <div class="box-section-body py-0">
                            <el-form-item label="Package Weight:" class="mb-1">
                                <el-input v-model="form.packageWeight">
                                    <template slot="append">oz</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="Handling Time:" class="mb-1">
                                <el-input v-model="form.handlingTime">
                                    <template slot="append">day</template>
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                </b-col>
                <b-col cols="5">
                    <div class="box-section mb-3 pb-1">
                        <div class="box-section-header">SHIPPING ADDRESS</div>
                        <div class="box-section-body py-2">
                            <el-form-item label="Company Name:" class="mb-1">
                                <el-input v-model="form.companyName"></el-input>
                            </el-form-item>
                            <el-form-item label="Company Address 1:" class="mb-1">
                                <el-input v-model="form.companyAddress"></el-input>
                            </el-form-item>
                            <el-form-item label="Company Address 2:" class="mb-1">
                                <el-input v-model="form.companyAddress2"></el-input>
                            </el-form-item>
                            <el-form-item label="Company City:" class="mb-1">
                                <el-input v-model="form.companyCity"></el-input>
                            </el-form-item>
                            <el-form-item label="Company State:" class="mb-1">
                                <el-input v-model="form.companyState"></el-input>
                            </el-form-item>
                            <el-form-item label="Company Phone:" class="mb-1">
                                <el-input v-model="form.companyPhone"></el-input>
                            </el-form-item>
                            <el-form-item label="Company Postal Code:" class="mb-1">
                                <el-input v-model="form.companyPostalCode"></el-input>
                            </el-form-item>
                            <el-form-item label="Company Country:" class="mb-1">
                                <el-input v-model="form.companyCountry" :disabled="true"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="text-center">
                <el-button type="success" native-type="submit">Save Changes</el-button>
            </div>
        </el-form>
    </div>
</template>
<style>
.setting-shipping__content .el-form-item__label {
    margin-top: 12px;
}
</style>
