<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';
import FormUserManage from '../../drawers/setting/FormUserManage.vue';
import FormGroupManage from '../../drawers/setting/FormGroupManage.vue';

export default {
  name: 'SettingUserPage',
  components: {
    FormUserManage,
    FormGroupManage
  },
  props: {},
  data() {
    return {
        onPageTabLoad: true,
        tableData: [],
        groupColumns: [
            {
                key: "name",
                value: 'Name',
                sortable: false,
            },
            {
                key: "description",
                value: 'Description',
                sortable: false,
                center: true
            },
            {
                key: "action",
                value: 'Action',
                sortable: false,
                center: true
            }
        ],
        groupTableData: [],
        columns: [
            {
                key: "first_name",
                value: 'First Name',
                sortable: false,
            },
            {
                key: "last_name",
                value: 'Last Name',
                sortable: false,
                center: true
            },
            {
                key: "email",
                value: 'Email',
                sortable: false,
            },
            {
                key: "companyName",
                value: 'Groups',
                sortable: false,
            },
            {
                key: "phone",
                value: 'Applications',
                sortable: false,
            },
            {
                key: "active",
                value: 'Active',
                sortable: false,
            },
            {
                key: "action",
                value: 'Action',
                sortable: false,
                center: true
            }
        ],
        selectedUser: {
            id: 0
        },
        selectedGroup: {
            id: 0
        },
        displayUserFormDrawer: false,
        displayGroupFormDrawer: false,
        tab: ''
    }
  },
  mixins: [
    common
  ],
  methods: {
    preventTabClick(tab, oldTab) {
      if (typeof oldTab === 'string' && oldTab.length > 1) {
        location.href = '/settings/' + tab
      }
      return this.onPageTabLoad && (oldTab == 0 || !oldTab);
    },
    handleTabClick() {
      this.onPageTabLoad = false
      return this.onPageTabLoad;
    },
    loadPageData() {
        let _vm = this
        return GetApiActionWithAuthorization("admin/setting/user/get-page-data").then(res => {
            _vm.tableData = res.data.map(v=>{ return {
                ...v,
                activeStatus: v.active ? 'Yes' : 'No'
            }})
        });
    },
    loadGroupPageData() {
        let _vm = this
        return GetApiActionWithAuthorization("admin/setting/group/get-page-data").then(res => {
            _vm.groupTableData = res.data
        });
    },
    addGroupData() {
        let _vm = this
        _vm.selectedGroup = {
            id: 0,
            name: '',
            description: '',
        }
        _vm.displayGroupFormDrawer = true
    },
    editGroupData(group) {
        let _vm = this
        _vm.selectedGroup = group
        _vm.displayGroupFormDrawer = true
    },
    addData() {
        let _vm = this
        _vm.selectedUser = {
            id: 0,
            first_name: '',
            last_name: '',
            email: '',
            companyName: '',
            phone: ''
        }
        _vm.displayUserFormDrawer = true
    },
    editData(user) {
        let _vm = this
        _vm.selectedUser = user
        _vm.displayUserFormDrawer = true
    },
    deleteData(userId) {
        if (confirm('Are you sure that you want delete this user ?')) {
            let _vm = this
            PostApiWithAuthorizationAction("admin/setting/user/delete/" + userId).then(() => {
                _vm.tableData = _vm.tableData.filter(v=>v.id!=userId)
            });
        }
    },
    deleteGroupData(groupId) {
        if (confirm('Are you sure that you want delete this group ?')) {
            let _vm = this
            PostApiWithAuthorizationAction("admin/setting/group/delete/" + groupId).then(() => {
                _vm.groupTableData = _vm.groupTableData.filter(v=>v.id!=groupId)
            });
        }
    },
    setActiveStatus(index) {
        let _vm = this
        let row = _vm.tableData[index]
        let active = row.activeStatus == 'Yes' ? 1 : 0
        PostApiWithAuthorizationAction("admin/setting/user/change-status/" + row.id, {
            active
        });
    }
  },
  computed: {},
  mounted () {
    let _vm = this
    _vm.tab = _vm.$route.path.split('/')[2] || ''
    _vm.loadPageData().then(() => {
        return _vm.loadGroupPageData()
    })
    //
    _vm.$root.$on('hide-owner-manage-drawer', () => {
      _vm.displayUserFormDrawer = false
    })
  }
}
</script>
<template>
    <div class="setting-user__content">
        <el-drawer
            ref="userDrawer"
            :title="selectedUser.id == 0 ? 'Add New User' : 'Update User'"
            :visible.sync="displayUserFormDrawer"
            :close-on-press-escape="false"
            :with-header="true"
            :wrapperClosable="false"  
            :append-to-body="true"  
            custom-class="common-drawer">
            <form-user-manage :data="selectedUser"/>
        </el-drawer>
        <el-drawer
            ref="groupDrawer"
            :title="selectedGroup.id == 0 ? 'Add New Group' : 'Update Group'"
            :visible.sync="displayGroupFormDrawer"
            :close-on-press-escape="false"
            :with-header="true"
            :wrapperClosable="false"
            :append-to-body="true"
            custom-class="common-drawer">
            <form-group-manage :data="selectedGroup"/>
        </el-drawer>
        <el-tabs
            v-model="tab"
            type="border-card"
            :before-leave="preventTabClick"
            @tab-click="handleTabClick"
            stretch
        >
            <el-tab-pane name="users">
                <template #label>
                    <a :href="'/settings/users'" class="setting-tab-link" style="color: white">
                        <i :class="['font-size-16', 'fas fa-user']"></i> Users
                    </a>
                </template>
                <div class="mb-3 text-right">
                    <b-button
                        variant="primary"
                        style="margin-right: 10px"
                        @click="addData()"
                    >
                    <i class="fas fa-plus-circle"></i> Create New User
                    </b-button>
                    <b-button
                        variant="primary"
                        style="margin-right: 10px"
                        @click="addGroupData()"
                    >
                        <i class="fas fa-plus-circle"></i> Create New Group
                    </b-button>
                </div>
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount="10"
                  class="table b-table"
                  style="background-color: rgba(0, 0, 0, 0.05); border: 5px solid #5b5f70"
                >
                  <thead
                    role="rowgroup"
                    class=""
                    style="background-color: #74788d; color: white"
                  >
                    <tr role="row" class="">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex="1"
                        :class="[
                          column.center ? 'text-center': '',
                          'px-4'
                        ]"
                        v-for="column in columns"
                        :key="column.key"
                      >
                        <div>{{ column.value }}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <tr
                      role="row"
                      aria-rowindex="1"
                      class=""
                      v-for="(data, index) in tableData"
                      :key="data.id"
                    >
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            {{ data.first_name }}
                        </td>
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            {{ data.last_name }}
                        </td>
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            {{ data.email }}
                        </td>
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            <div
                                v-for="(group, i) in data.UserGroups"
                                :key="i"
                                class="mb-1">
                                <el-button type="primary" round size="mini">{{ group.Group.name }}</el-button>
                            </div>
                        </td>
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            <div
                                v-for="(customer, i) in data.Customers"
                                :key="i"
                                class="mb-1">
                                <el-button type="primary" round size="mini">{{ customer.Application.name }}</el-button>
                            </div>
                        </td>
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            <el-radio-group
                                v-model="data.activeStatus"
                                size="small"
                                @change="setActiveStatus(index)">
                                <el-radio-button label="No"></el-radio-button>
                                <el-radio-button label="Yes"></el-radio-button>
                            </el-radio-group>
                        </td>
                        <td
                            aria-colindex="2"
                            role="cell"
                            :class="['text-center', 'px-0']">
                            <div class="mb-1">
                                <b-button
                                    variant="warning"
                                    style="margin-right: 5px"
                                    @click="editData(data)"
                                >
                                    <i class="fas fa-edit"></i>
                                </b-button>
                                <b-button
                                    variant="danger"
                                    @click="deleteData(data.id)"
                                >
                                <i class="fas fa-trash-alt"></i>
                                </b-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </el-tab-pane>
            <el-tab-pane name="groups">
                <template #label>
                    <a :href="'/settings/groups'" class="setting-tab-link" style="color: white">
                        <i :class="['font-size-16', 'fas fa-users-cog']"></i> Groups
                    </a>
                </template>
                <div class="mb-3 text-right">
                    <b-button
                        variant="primary"
                        style="margin-right: 10px"
                        @click="addGroupData()"
                    >
                        <i class="fas fa-plus-circle"></i> Create New Group
                    </b-button>
                </div>
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount="10"
                  class="table b-table"
                  style="background-color: rgba(0, 0, 0, 0.05); border: 5px solid #5b5f70"
                >
                  <thead
                    role="rowgroup"
                    class=""
                    style="background-color: #74788d; color: white"
                  >
                    <tr role="row" class="">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex="1"
                        :class="[
                          column.center ? 'text-center': '',
                          'px-4'
                        ]"
                        v-for="column in groupColumns"
                        :key="column.key"
                      >
                        <div>{{ column.value }}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <tr
                      role="row"
                      aria-rowindex="1"
                      class=""
                      v-for="(data) in groupTableData"
                      :key="data.id"
                    >
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            {{ data.name }}
                        </td>
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            {{ data.description }}
                        </td>
                        <td
                            aria-colindex="2"
                            role="cell"
                            :class="['text-center', 'px-0']">
                            <div class="mb-1">
                                <b-button
                                    variant="warning"
                                    style="margin-right: 5px"
                                    @click="editGroupData(data)"
                                >
                                    <i class="fas fa-edit"></i>
                                </b-button>
                                <b-button
                                    variant="danger"
                                    @click="deleteGroupData(data.id)"
                                >
                                <i class="fas fa-trash-alt"></i>
                                </b-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style>
.setting-user__content .el-tabs__item {
    background: #409eff !important;
    color: white !important;
}
.setting-user__content .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-top: none !important;
    border-bottom: 3px solid #0c67c5 !important;
    background: #409eff !important;
    color: white !important;
}
</style>