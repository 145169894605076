export const listing = {
    data() {
      return {}
    },

    mounted() {
        let _vm = this
        _vm.$root.$on('append-new-item-to-data', params => {
            const item = params.item,
                  drawer = params.drawer,
                  tableData = params.tableData
            let findTableItemIndex = _vm[tableData].findIndex(v=>v.id==item.id)
            if (findTableItemIndex >= 0) {
                _vm[tableData][findTableItemIndex] = item
            } else {
                _vm[tableData].unshift(item)
            }
            _vm[drawer] = false
        })
    }
}