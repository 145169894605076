<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import SettingGeneralPage from "@/components/pages/settings/general";
import SettingOwnerPage from "@/components/pages/settings/owner";
import SettingStoreCategoryPage from "@/components/pages/settings/storeCategory";
import SettingUserPage from "@/components/pages/settings/user";
import SettingIpWhitelist from "@/components/pages/settings/ipWhitelist";
import SettingProductTag from "@/components/pages/settings/productTag";
import SettingFlaggedKeyword from "@/components/pages/settings/flaggedKeyword";
import SettingItemSpecific from "@/components/pages/settings/itemSpecific";
import SettingShippingPage from "@/components/pages/settings/shipping";
import SettingPreset from "@/components/pages/settings/preset";
import SettingMode from "@/components/pages/settings/mode";
import SettingDailyPostage from "@/components/pages/settings/dailyPost";
import SettingSocialMediaPage from "@/components/pages/settings/socialMedia";
import { GetApiActionWithAuthorization } from '../../../helpers/apiActions';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Products"
  },
  components: {
    Layout,
    PageHeader,
    SettingGeneralPage,
    SettingOwnerPage,
    SettingStoreCategoryPage,
    SettingUserPage,
    SettingIpWhitelist,
    SettingProductTag,
    SettingFlaggedKeyword,
    SettingItemSpecific,
    SettingShippingPage,
    SettingDailyPostage,
    SettingSocialMediaPage,
    SettingPreset,
    SettingMode
  },
  data() {
    return {
      title: "",
      items: [],
      tab: '',
      settingModel: {},
      users: [],
      onPageTabLoad: true,
      pageFullyLoaded: true,
      tabs: [
        {
          value: 'General',
          link: 'general',
          icon: 'fas fa-flag',
          active: false
        },
        {
          value: 'Users',
          link: 'users',
          icon: 'fas fa-users',
          active: false
        },
        {
          value: 'Owners',
          link: 'owners',
          icon: 'fas fa-building',
          active: false
        },
        {
          value: 'Applications',
          link: 'applications',
          icon: 'fas fa-store',
          active: false
        },
        {
          value: 'Modes',
          link: 'modes',
          icon: 'fab fa-modx',
          active: false
        },
        {
          value: 'Store Categories',
          link: 'store_categories',
          icon: 'fab fa-pagelines',
          active: false
        },
        {
          value: 'Social Medias',
          link: 'social_medias',
          icon: 'fas fa-network-wired',
          active: false
        },
        {
          value: 'Shipping',
          link: 'shipping',
          icon: 'fas fa-truck',
          active: false
        },
        {
          value: 'Daily Post',
          link: 'daily_post',
          icon: 'fas fa-list-alt',
          active: false
        },
        {
          value: 'Presets',
          link: 'presets',
          icon: 'fas fa-tools',
          active: false
        },
        {
          value: 'Item Specifics',
          link: 'item_specifics',
          icon: 'fas fa-list-alt',
          active: false
        },
        {
          value: 'Flagged',
          link: 'flagged_keywords',
          icon: 'fas fa-sticky-note',
          active: false
        },
        {
          value: 'Product Tags',
          link: 'product_tags',
          icon: 'fas fa-tags',
          active: false
        },
        {
          value: 'IP Whitelist',
          link: 'ip-whitelist',
          icon: 'fas fa-robot',
          active: false
        },
      ]
    }
  },
  mounted() {
    let _vm = this
    _vm.loadPageData().then(() => {
      _vm.tab = _vm.$route.path.split('/')[2] || 'general'
      _vm.tab = (_vm.tab === 'groups') ? 'users' : _vm.tab
      let findActiveTabIndex = _vm.tabs.findIndex(m=>m.link==_vm.tab) || 0
      _vm.$set(_vm.tabs, findActiveTabIndex, Object.assign(_vm.tabs[findActiveTabIndex], {
        active: true
      }));
      _vm.title = "SETTINGS - " + _vm.tabs[findActiveTabIndex].value
    })
    //
    _vm.$root.$on('display-page', () => {
      _vm.pageFullyLoaded = true
    })
  },
  methods: {
    preventTabClick(tab, oldTab) {
      if (typeof oldTab === 'string' && oldTab.length > 1) {
        location.href = '/settings/' + tab
      }
      return this.onPageTabLoad && (oldTab == 0 || !oldTab);
    },
    handleTabClick() {
      this.onPageTabLoad = false
      return this.onPageTabLoad;
    },
    loadPageData () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-page-data").then(res => {
        _vm.settingModel = res.setting
        _vm.users = res.users
      });
    }
  }
}
</script>
<template>
    <div class="setting-general-page">
        <Layout>
            <PageHeader
                :title="title"
                :items="items"
                icon="bx bx-wrench"
                v-if="title.length > 0 && pageFullyLoaded"/>
            <div
              class="setting-page-container"
              v-if="pageFullyLoaded && title != ''"
              style="height: 100%;">
                <el-tabs
                    type="border-card"
                    v-model="tab"
                    :before-leave="preventTabClick"
                    @tab-click="handleTabClick">
                    <el-tab-pane
                      v-for="_tab in tabs"
                      :key="_tab.link"
                      :active="_tab.active"
                      :name="_tab.link"
                    >
                        <template #label>
                          <a :href="'/settings/' + _tab.link" class="product-tab-link">
                            <i :class="['font-size-16', _tab.icon]"></i> {{ _tab.value }}
                          </a>
                        </template>
                        <!-- Setting General Tab -->
                        <SettingGeneralPage
                          :settingModel="settingModel"
                          v-if="tab==_tab.link && tab=='general'"/>
                        <!-- Setting User Tab -->
                        <SettingUserPage v-if="tab==_tab.link && tab=='users'"/>
                        <!-- Setting Owner Tab -->
                        <SettingOwnerPage v-if="tab==_tab.link && tab=='owners'"/>
                        <!-- Store Categories Tab -->
                        <SettingStoreCategoryPage v-if="tab==_tab.link && tab=='store_categories'"/>
                        <!-- IP Whitelist -->
                        <SettingIpWhitelist v-if="tab==_tab.link && tab=='ip-whitelist'"/>
                        <!-- Product Tags -->
                        <SettingProductTag v-if="tab==_tab.link && tab=='product_tags'"/>
                        <!-- Flagged Keywords -->
                        <SettingFlaggedKeyword v-if="tab=='flagged_keywords'" />
                        <!-- Item Specifics -->
                        <SettingItemSpecific v-if="tab==_tab.link && tab=='item_specifics'"/>
                        <!-- Shipping Page -->
                        <SettingShippingPage v-if="tab==_tab.link && tab=='shipping'"/>
                        <!-- Daily Post Page -->
                        <SettingDailyPostage v-if="tab==_tab.link && tab=='daily_post'"/>
                        <!-- Social Media Page -->
                        <SettingSocialMediaPage v-if="tab==_tab.link && tab=='social_medias'"/>
                        <!-- Presets -->
                        <SettingPreset v-if="tab==_tab.link && tab=='presets'"/>
                        <!-- Modes -->
                        <SettingMode v-if="tab==_tab.link && tab=='modes'"/>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </Layout>
    </div>
</template>