<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';
import FormIpWhitelistManage from '../../drawers/setting/FormIpWhitelistManage.vue';

export default {
  name: 'SettingIPWhitelistPage',
  components: {FormIpWhitelistManage},
  props: {},
  data() {
    return {
        tableData: [],
        fields: [
            {
                key: "ip",
                value: 'IP',
                sortable: false,
            },
            {
                key: "comment",
                value: 'Comment',
                sortable: false,
            },
            {
                key: "added_on",
                value: 'Added On',
                sortable: false,
            },
            {
                key: "action",
                value: 'Action',
                sortable: false,
            }
        ],
        selectedIP: {
            id: 0,
            ip: '',
            comment: ''
        },
        pagination: {
            pageSize: 50,
            page: 1,
            count: 0
        },
        displayipWhitelistFormDrawer: false
    }
  },
  mixins: [
    common
  ],
  methods: {
    loadPageData(params = {}) {
        let _vm = this,
            page = (typeof params.page !== 'undefined') ? params.page : _vm.pagination.page
        return GetApiActionWithAuthorization("admin/ip-whitelist/get-page-data?pagination[pageSize]=" + _vm.pagination.pageSize + "&pagination[page]=" + page).then(res => {
            _vm.tableData = res.data
            _vm.pagination.count = res.count
        });
    },
    addData() {
        let _vm = this
        _vm.selectedIP = {
            id: 0,
            ip: '',
            comment: ''
        }
        _vm.displayipWhitelistFormDrawer = true
    },
    editData(ip) {
        let _vm = this
        _vm.selectedIP = ip
        _vm.displayipWhitelistFormDrawer = true
    },
    deleteData(userId) {
        if (confirm('Are you sure that you want delete this owner ?')) {
            let _vm = this
            PostApiWithAuthorizationAction("admin/ip-whitelist/delete/" + userId).then(() => {
                _vm.tableData = _vm.tableData.filter(v=>v.id!=userId)
            });
        }
    }
  },
  computed: {},
  mounted () {
    let _vm = this
    _vm.loadPageData()
  },
  watch: {
    'pagination.page': function(page) {
      this.loadPageData({ page })
    }
  }
}
</script>
<template>
    <div class="setting-ip-whitelist__content">
        <el-drawer
            ref="ipWhitelistDrawer"
            :title="selectedIP.id == 0 ? 'Add New IP' : 'Update IP'"
            :visible.sync="displayipWhitelistFormDrawer"
            :close-on-press-escape="false"
            :with-header="true"
            :wrapperClosable="false"  
            :append-to-body="true"
            custom-class="common-drawer">
            <form-ip-whitelist-manage :data="selectedIP"/>
        </el-drawer>
        <div class="my-3">
            <b-button
                variant="primary"
                @click="addData()"
            >
            <i class="fas fa-plus-circle"></i> Add New +
            </b-button>
        </div>
        <table
                role="table"
                aria-busy="false"
                aria-colcount="10"
                class="table b-table"
            >
            <thead
                role="rowgroup"
                class=""
                style="background-color: #74788d; color: white"
                >
                <tr role="row" class="">
                    <th
                        role="columnheader"
                        scope="col"
                        aria-colindex="1"
                        :class="[
                            field.center ? 'text-center': '',
                            'px-4'
                        ]"
                        v-for="field in fields"
                        :key="field.key"
                        >
                        <div>{{ field.value }}</div>
                    </th>
                </tr>
                </thead>
                <tbody role="rowgroup">
                <tr
                    role="row"
                    aria-rowindex="1"
                    class=""
                    v-for="data in tableData"
                    :key="data.id"
                >
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px">
                        <i class="fas fa-robot" v-if="data.fromBot"></i> {{ data.ip }}
                    </td>
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px">
                        {{ data.comment }}
                    </td>
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px">
                        {{ getFormattedData(data.createdAt) }}
                    </td>
                    <td
                            aria-colindex="2"
                            role="cell"
                            :class="['text-center', 'px-0']">
                            <div class="mb-1">
                                <b-button
                                    variant="warning"
                                    style="margin-right: 5px"
                                    @click="editData(data)"
                                >
                                    <i class="fas fa-edit"></i>
                                </b-button>
                                <b-button
                                    variant="danger"
                                    @click="deleteData(data.id)"
                                >
                                <i class="fas fa-trash-alt"></i>
                                </b-button>
                            </div>
                        </td>
                </tr>
            </tbody>
        </table>
        <div class="row">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded mb-0">
                        <b-pagination
                            v-model="pagination.page"
                            :total-rows="pagination.count"
                            :per-page="pagination.pageSize"
                            ></b-pagination>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .el-color-picker--medium,
    .el-color-picker--medium .el-color-picker__trigger {
        width: 100%;
    }
</style>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
    width: 50%
}
</style>
 