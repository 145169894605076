<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'SettingStoreCategoryPage',
  components: {},
  props: {},
  data() {
    return {
        tableData: [],
        fields: [
            {
                key: "name",
                value: 'Name',
                sortable: false,
            },
            {
                key: "color",
                value: 'Color',
                sortable: false,
            }
        ]
    }
  },
  mixins: [
    common
  ],
  methods: {
    loadPageData() {
        let _vm = this
        return GetApiActionWithAuthorization("admin/common/get-store-categories").then(res => {
            _vm.tableData = res.data
        });
    },
    setColor(index) {
        let _vm = this,
            item = _vm.tableData[index]
        PostApiWithAuthorizationAction("admin/common/update-store-category-by-name", {
            name: item.name,
            color: item.color
        });
    }
  },
  computed: {},
  mounted () {
    let _vm = this
    _vm.loadPageData()
  }
}
</script>
<template>
    <div class="setting-general__content">
        <div class="">
            <b-button type="button" variant="warning" class="mb-2">
                <i class="fas fa-exclamation-triangle"></i> Sync Store Categories
            </b-button>
        </div>
        <table
                role="table"
                aria-busy="false"
                aria-colcount="10"
                class="table b-table"
            >
                <thead
                role="rowgroup"
                class=""
                style="background-color: #74788d; color: white"
                >
                <tr role="row" class="">
                    <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    :class="[
                        field.center ? 'text-center': '',
                        'px-4'
                    ]"
                    v-for="field in fields"
                    :key="field.key"
                    >
                    <div>{{ field.value }}</div>
                    </th>
                </tr>
                </thead>
                <tbody role="rowgroup">
                <tr
                    role="row"
                    aria-rowindex="1"
                    class=""
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px">
                        {{ data.name }}
                    </td>
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px">
                        <el-color-picker
                            v-model="data.color"
                            size="medium"
                            @change="setColor(index)"></el-color-picker>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<style>
    .el-color-picker--medium,
    .el-color-picker--medium .el-color-picker__trigger {
        width: 100%;
    }
</style>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
    width: 50%
}
</style>
