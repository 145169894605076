<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'SettingDailyPostPage',
  components: {},
  props: {},
  data() {
    return {
        tabPosition: 1,
        formData: [
            { day: 1, timeStart: '17:00', timeEnd: '18:00', quantity: 0 },
            { day: 2, timeStart: '17:00', timeEnd: '18:00', quantity: 0 },
            { day: 3, timeStart: '17:00', timeEnd: '18:00', quantity: 0 },
            { day: 4, timeStart: '17:00', timeEnd: '18:00', quantity: 0 },
            { day: 5, timeStart: '17:00', timeEnd: '18:00', quantity: 0 },
            { day: 6, timeStart: '17:00', timeEnd: '18:00', quantity: 0 },
            { day: 7, timeStart: '17:00', timeEnd: '18:00', quantity: 0 }
        ],
        weekDays: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday'
        ]
    }
  },
  mixins: [
    common
  ],
  methods: {
    onSubmit() {
        let _vm = this
        return PostApiWithAuthorizationAction("admin/setting/daily-post/save-page-data/", {
            times: _vm.formData,
            type: _vm.tabPosition
        }).then(() => {
            _vm.$toast.success("Changes saved successfully.", {
                position: "top-right",
                timeout: 1000,
                closeOnClick: true
            });
        });        
    },
    loadPageData() {
        let _vm = this
        return GetApiActionWithAuthorization("admin/setting/daily-post/get-page-data/" + _vm.tabPosition).then(res => {
            _vm.formData = res.data
        });
    }
  },
  computed: {},
  mounted () {
    this.loadPageData()
  },
  watch: {}
}
</script>
<template>
    <div class="setting-dailyPost__content">
        <el-form
            ref="form"
            label-width="180px"
            @submit.prevent.native="onSubmit()">
            <b-row class="mx-0">
                <b-col cols="6">
                    <div class="box-section mb-3 pb-1">
                        <div class="box-section-header">DAILY POST LIMIT</div>
                        <div class="box-section-body py-2">
                            <div class="text-center mb-2">
                                <el-radio-group v-model="tabPosition" @change="loadPageData()">
                                    <el-radio-button label="1">Listing</el-radio-button>
                                    <el-radio-button label="2">Relisting</el-radio-button>
                                </el-radio-group>
                            </div>
                            <div class="mb-2">
                                <b-row
                                    v-for="(item, index) in formData"
                                    :key="index">
                                    <b-col cols="2" class="py-2">
                                        <strong style="font-size: 14px">{{ weekDays[index] }}</strong>
                                    </b-col>
                                    <b-col cols="4" class="pb-2">
                                        <el-time-select
                                            v-model="item.timeStart"
                                            :picker-options="{
                                                start: '08:00',
                                                step: '01:00',
                                                end: '23:00'
                                            }"
                                            :editable="false"
                                            :clearable="false"
                                            style="width: 100%">
                                        </el-time-select>
                                    </b-col>
                                    <b-col cols="4" class="pb-2">
                                        <el-time-select
                                            v-model="item.timeEnd"
                                            :picker-options="{
                                                start: '08:00',
                                                step: '01:00',
                                                end: '23:00',
                                                minTime: item.timeStart
                                            }"
                                            :editable="false"
                                            :clearable="false"
                                            style="width: 100%">
                                        </el-time-select>
                                    </b-col>
                                    <b-col cols="2" class="pb-2">
                                        <el-input v-model="item.quantity" type="number"></el-input>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="text-center">
                                <el-button type="success" native-type="submit">Save Changes</el-button>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </el-form>
    </div>
</template>
<style>
.setting-shipping__content .el-form-item__label {
    margin-top: 12px;
}
</style>
