<script>
import {  common } from '@/mixins/common';
import { PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'FormProductTagManage',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
        form: {
            id: 0,
            tag: ''
        },
        rules: {
            tag: [
                { required: true, message: ' ', trigger: 'change' }
            ]
        }
    }
  },
  mixins: [
    common
  ],
  methods: {
    submitForm () {
        let _vm = this
        _vm.$refs.formProductTagManager.validate((valid) => {
            if (valid) {
                PostApiWithAuthorizationAction("admin/product-tag/save-data/", _vm.form).then(res => {
                    _vm.$root.$emit('append-new-item-to-data', {
                        item: res.data,
                        drawer: 'displayProductTagFormDrawer',
                        tableData: 'tableData'
                    })
                });
            }
        });
    }
  },
  computed: {},
  mounted () {
    this.form = {
        ... this.data
    }
  },
  watch: {
    data: function(newValue) {
        let _vm = this
        _vm.form = {
            ... newValue
        }
        //
        if (_vm.$refs.drawerPrimaryField) {
          _vm.$refs.drawerPrimaryField.focus()
        }
    }
  }
}
</script>
<template>
    <div class="demo-drawer__content">
        <el-form
            :model="form"
            ref="formProductTagManager"
            @submit.native.prevent="submitForm">
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <el-form-item
                        class="mb-0"
                        :rules="rules.tag"
                        prop="tag"
                        label="Tag">
                        <el-input v-model="form.tag" ref="drawerPrimaryField"></el-input>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mx-0 text-right">
                <b-col cols="12">
                    <el-button
                        type="success"
                        native-type="submit">
                        <i class="fas fa-save"></i> Save Changes
                    </el-button>
                </b-col>
            </b-row>
        </el-form>
    </div>
</template>