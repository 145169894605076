<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'FormUserManage',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    var validateUniqueEmail = (rule, value, callback) => {
        PostApiWithAuthorizationAction("admin/common/verify-unique-email/", {
            email: this.form.email,
            id: this.form.id
        }).then(res => {
            if (res.exists) {
                callback(new Error('Email already exist in the system'));
            }
            callback();
        });
    };
    var validatePassword = (rule, value, callback) => {
        if (value !== '') {
            if (value.length < 8) {
                callback(new Error('Password must have 8 characters min.'));
            } else {
                if (this.form.confirm_password !== '') {
                    this.$refs.formUserManager.validateField('confirm_password');
                }
                callback();
            }
            callback();
        } else if (value == '' && this.form.id == 0) {
            callback(new Error(' '));
        }
        callback();
    };
    var validateConfirmPassword = (rule, value, callback) => {
        if (value === '' && this.form.new_password !== '') {
            callback(new Error('You must verify user password.'));
        } else if (value !== this.form.new_password) {
            callback(new Error('Password do not match'));
        } else {
            callback();
        }
    };
    var validateGroup = (rule, value, callback) => {
        if (value && this.form.selectedGroups.length == 0) {
            callback(new Error(' '));
        }
        callback();
    };
    return {
        form: {
            id: 0,
            first_name: '',
            last_name: '',
            email: '',
            active: 1,
            new_password: '',
            confirm_password: '',
            selectedApplications: [],
            selectedGroups: []
        },
        rules: {
            first_name: [{ required: true, message: ' ', trigger: ['blur', 'change'] }],
            last_name: [{ required: true, message: ' ', trigger: ['blur', 'change'] }],
            email: [
                { required: true, message: ' ', trigger: ['blur', 'change'] },
                { type: 'email', message: 'Valid Address Email required', trigger: ['blur', 'change'] },
                { validator: validateUniqueEmail, trigger: ['blur', 'change'] }
            ],
            selectedGroups: [
                { validator: validateGroup, trigger: ['blur', 'change'] }
            ],
            new_password: [
                { validator: validatePassword, trigger: ['blur', 'change'] }
            ],
            confirm_password: [
                { validator: validateConfirmPassword, trigger: ['blur', 'change'] }
            ],
        },
        applications: [],
        groups: []
    }
  },
  mixins: [
    common
  ],
  methods: {
    loadGroups () {
        let _vm = this
        return GetApiActionWithAuthorization("admin/common/get-groups").then(res => {
            _vm.groups = res.data
        });
    },
    loadApplications () {
        let _vm = this
        return GetApiActionWithAuthorization("admin/common/get-applications").then(res => {
            _vm.applications = res.data
        });
    },
    onSubmit () {
        let _vm = this
        _vm.$refs.formUserManager.validate((valid) => {
            let form = _vm.form
           if (valid) {
                PostApiWithAuthorizationAction("admin/setting/user/save-data/", form).then(() => {
                    _vm.$root.$emit('hide-owner-manage-drawer')
                    location.reload()
                });
            } else {
                return false;
            }
        });
    },
    fillFormFields(data) {
        let _vm = this,
            selectedApplications = data.Customers ? data.Customers.map(c=>c.Application.id) : [],
            selectedGroups = data.UserGroups ? data.UserGroups.map(u=>u.Group.id) : []
        //
        _vm.form = {
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            active: data.active || 1,
            new_password: data.new_password || '',
            confirm_password: data.confirm_password || '',
            selectedApplications,
            selectedGroups
        }
    }
  },
  computed: {},
  mounted () {
    // load data
    this.loadGroups().then(() => {
        return this.loadApplications()
    }).then(() => {
        this.fillFormFields(this.data)
    })
  },
  watch: {
    data: function(newValue) {
        this.fillFormFields(newValue)
    }
  }
}
</script>
<template>
    <div class="demo-drawer__content">
        <el-form :model="form" ref="formUserManager">
                <b-row class="mb-2 mx-0">
                    <b-col cols="6">
                        <label class="mb-0">First Name</label>
                        <el-form-item class="mb-0" :rules="rules.first_name" prop="first_name">
                            <el-input
                                v-model="form.first_name"
                                placeholder="First Name"
                                autofocus></el-input>
                        </el-form-item>
                    </b-col>
                    <b-col cols="6">
                        <label class="mb-0">Last Name</label>
                        <el-form-item class="mb-0" :rules="rules.last_name" prop="last_name">
                            <el-input
                                v-model="form.last_name"
                                placeholder="Last Name"
                                ></el-input>
                        </el-form-item>
                    </b-col>
                </b-row>
            <b-row class="mb-3 mx-0">
                <b-col cols="12">
                    <label class="mb-0">Email</label>
                    <el-form-item class="mb-0" :rules="rules.email" prop="email">
                        <el-input
                            v-model="form.email"
                            placeholder="Email"
                            ></el-input>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mb-3 mx-0">
                <b-col cols="12">
                    <label class="mb-0">Applications</label>
                    <el-select
                        v-model="form.selectedApplications"
                        placeholder="Applications"
                        size="medium"
                        style="width: 100%"
                        multiple
                        >
                        <el-option
                            v-for="(app, index) in applications"
                            :key="index"
                            :label="app.name"
                            :value="app.id"
                        ></el-option>
                    </el-select>
                </b-col>
            </b-row>
            <b-row class="mb-3 mx-0">
                <b-col cols="12">
                    <label class="mb-0">Groups</label>
                    <el-form-item class="mb-0" :rules="rules.selectedGroups" prop="selectedGroups">
                        <el-select
                            v-model="form.selectedGroups"
                            placeholder="Groups"
                            style="width: 100%"
                            size="medium"
                            multiple
                            >
                            <el-option
                                v-for="(group, index) in groups"
                                :key="index"
                                :label="group.name"
                                :value="group.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mb-3 mx-0">
                <b-col cols="12">
                    <label class="mb-0">Password</label>
                    <el-form-item class="mb-0" :rules="rules.new_password" prop="new_password">
                        <el-input
                            v-model="form.new_password"
                            placeholder="Password"
                            type="password"
                            ></el-input>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <label class="mb-0">Confirm Password</label>
                    <el-form-item class="mb-0" :rules="rules.confirm_password" prop="confirm_password">
                        <el-input
                        v-model="form.confirm_password"
                        placeholder="Confirm Password"
                        type="password"
                        ></el-input>   
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mx-0 text-right">
                <b-col cols="12">
                    <b-button
                        variant="success"
                        @click="onSubmit()"
                    >
                        <i class="fas fa-save"></i> Save Changes
                    </b-button>
                </b-col>
            </b-row>
        </el-form>
    </div>
</template>