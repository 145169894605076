<script>
import {  common } from '@/mixins/common';
import { PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'FormGroupManage',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
        form: {
            id: 0,
            name: '',
            description: ''
        },
        rules: {
            name: [{ required: true, message: ' ', trigger: ['blur', 'change'] }],
        },
    }
  },
  mixins: [
    common
  ],
  methods: {
    onSubmit () {
        let _vm = this
        _vm.$refs.formGroupManager.validate((valid) => {
            let form = _vm.form
           if (valid) {
                PostApiWithAuthorizationAction("admin/setting/group/save/", form).then(() => {
                    _vm.$root.$emit('hide-owner-manage-drawer')
                    location.reload()
                });
            } else {
                return false;
            }
        });
    },
    fillFormFields(data) {
        let _vm = this
        //
        _vm.form = {
            id: data.id,
            name: data.name,
            description: data.description,
        }
    }
  },
  computed: {},
  mounted () {
    // load data
    this.fillFormFields(this.data)
  },
  watch: {
    data: function(newValue) {
        this.fillFormFields(newValue)
    }
  }
}
</script>
<template>
    <div class="demo-drawer__content">
        <el-form :model="form" ref="formGroupManager">
            <b-row class="mb-3 mx-0">
                <b-col cols="12">
                    <label class="mb-0">Name</label>
                    <el-form-item
                        class="mb-0"
                        :rules="rules.name"
                        prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="Name"
                        ></el-input>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <label class="mb-0">Description</label>
                    <el-form-item
                        class="mb-0"
                    >
                        <el-input
                            type="textarea"
                            v-model="form.description"
                            placeholder="Description"
                        ></el-input>   
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mx-0 text-right">
                <b-col cols="12">
                    <b-button
                        variant="success"
                        @click="onSubmit()"
                    >
                        <i class="fas fa-save"></i> Save Changes
                    </b-button>
                </b-col>
            </b-row>
        </el-form>
    </div>
</template>