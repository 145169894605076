<script>
// import {
//   PostApiWithAuthorizationAction
// } from "@/helpers/apiActions";
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';
import FormOwnerManage from '../../drawers/setting/FormOwnerManage.vue';

export default {
  name: 'SettingOwnerPage',
  components: {FormOwnerManage},
  props: {},
  data() {
    return {
        tableData: [],
        fields: [
            {
                key: "firstName",
                value: 'FirstName',
                sortable: false,
            },
            {
                key: "lastName",
                value: 'LastName',
                sortable: false,
                center: true
            },
            {
                key: "email",
                value: 'Email',
                sortable: false,
            },
            {
                key: "companyName",
                value: 'Company Name',
                sortable: false,
            },
            {
                key: "phone",
                value: 'Phone',
                sortable: false,
            },
            {
                key: "action",
                value: 'Action',
                sortable: false,
                center: true
            }
        ],
        selectedOwner: {
            id: 0
        },
        displayOwnerFormDrawer: false
    }
  },
  mixins: [
    common
  ],
  methods: {
    loadPageData() {
        let _vm = this
        return GetApiActionWithAuthorization("admin/setting/owner/get-page-data").then(res => {
            _vm.tableData = res.data
        });
    },
    addData() {
        let _vm = this
        _vm.selectedOwner = {
            id: 0,
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            phone: ''
        }
        _vm.displayOwnerFormDrawer = true
    },
    editData(user) {
        let _vm = this
        _vm.selectedOwner = user
        _vm.displayOwnerFormDrawer = true
    },
    deleteData(userId) {
        if (confirm('Are you sure that you want delete this owner ?')) {
            let _vm = this
            PostApiWithAuthorizationAction("admin/setting/owner/delete-user/" + userId).then(() => {
                _vm.tableData = _vm.tableData.filter(v=>v.id!=userId)
            });
        }
    }
  },
  computed: {},
  mounted () {
    let _vm = this
    _vm.loadPageData()
    //
    _vm.$root.$on('reload-owner-manage-drawer', () => {
        _vm.loadPageData()
        _vm.displayOwnerFormDrawer = false
    })
  }
}
</script>
<template>
    <div class="setting-general__content">
        <el-drawer
            ref="ownerDrawer"
            :title="selectedOwner.id == 0 ? 'Add New Owner' : 'Update Owner'"
            :visible.sync="displayOwnerFormDrawer"
            :close-on-press-escape="false"
            :with-header="true"
            :wrapperClosable="false"  
            :append-to-body="true"
            custom-class="common-drawer">
            <form-owner-manage :data="selectedOwner"/>
        </el-drawer>
        <div class="mb-1 text-right">
            <b-button
                variant="primary"
                @click="addData()"
            >
            <i class="fas fa-plus-circle"></i> New Owner
            </b-button>
        </div>
            <table
                  role="table"
                  aria-busy="false"
                  aria-colcount="10"
                  class="table b-table"
                  style="background-color: rgba(0, 0, 0, 0.05); border: 5px solid #5b5f70"
                >
                  <thead
                    role="rowgroup"
                    class=""
                    style="background-color: #74788d; color: white"
                  >
                    <tr role="row" class="">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex="1"
                        :class="[
                          field.center ? 'text-center': '',
                          'px-4'
                        ]"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <div>{{ field.value }}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <tr
                      role="row"
                      aria-rowindex="1"
                      class=""
                      v-for="data in tableData"
                      :key="data.id"
                    >
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            {{ data.firstName }}
                        </td>
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            {{ data.lastName }}
                        </td>
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            {{ data.email }}
                        </td>
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            {{ data.companyName }}
                        </td>
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px">
                            <a :href="'tel:' + data.phone">{{ data.phone }}</a>
                        </td>
                        <td
                            aria-colindex="2"
                            role="cell"
                            :class="['text-center', 'px-0']">
                            <div class="mb-1">
                                <b-button
                                    variant="warning"
                                    style="margin-right: 5px"
                                    @click="editData(data)"
                                >
                                    <i class="fas fa-edit"></i>
                                </b-button>
                                <b-button
                                    variant="danger"
                                    @click="deleteData(data.id)"
                                >
                                <i class="fas fa-trash-alt"></i>
                                </b-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
    </div>
</template>