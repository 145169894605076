<script>
import {  common } from '@/mixins/common';
import { PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'FormOwnerManage',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
        form: {
            id: 0,
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            phone: ''
        },
        rules: {
            firstName: [{ required: true, message: 'FirstName is required', trigger: 'blur' }],
            lastName: [{ required: true, message: 'LastName is required', trigger: 'blur' }]
        }
    }
  },
  mixins: [
    common
  ],
  methods: {
    onSubmit () {
        let _vm = this
        this.$refs.formOwnerManager.validate((valid) => {
            if (valid) {
                PostApiWithAuthorizationAction("admin/setting/owner/save-data/", _vm.form).then(() => {
                    _vm.$root.$emit('reload-owner-manage-drawer')
                });
            } else {
                return false;
            }
        });
    }
  },
  computed: {},
  mounted () {
    this.form = {
        ... this.data
    }
  },
  watch: {
    data: function(newValue) {
        let _vm = this
        _vm.form = {
            ... newValue
        }
    }
  }
}
</script>
<template>
    <div class="demo-drawer__content">
        <el-form :model="form" ref="formOwnerManager">
                <b-row class="mx-0">
                    <b-col cols="6">
                        <el-form-item :rules="rules.firstName" prop="firstName">
                            <el-input
                                v-model="form.firstName"
                                placeholder="Owner FirstName"
                                autofocus></el-input>
                        </el-form-item>
                    </b-col>
                    <b-col cols="6">
                        <el-form-item :rules="rules.lastName" prop="lastName">
                            <el-input
                                v-model="form.lastName"
                                placeholder="Owner LastName"
                                ></el-input>
                        </el-form-item>
                    </b-col>
                </b-row>
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <el-input
                    v-model="form.email"
                    placeholder="Owner Email"
                    ></el-input>   
                </b-col>
            </b-row>
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <el-input
                    v-model="form.companyName"
                    placeholder="Company Name"
                    ></el-input>   
                </b-col>
            </b-row>
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <el-input
                    v-model="form.phone"
                    placeholder="Phone"
                    ></el-input>   
                </b-col>
            </b-row>
            <b-row class="mx-0 text-right">
                <b-col cols="12">
                    <b-button
                        variant="success"
                        @click="onSubmit()"
                    >
                        <i class="fas fa-save"></i> Save Changes
                    </b-button>
                </b-col>
            </b-row>
        </el-form>
    </div>
</template>