<script>
import {  common } from '@/mixins/common';
import {  listing } from '@/mixins/listing';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';
import FormItemSpecificManage from '../../drawers/setting/FormItemSpecificManage.vue';

export default {
  name: 'SettingProductTagPage',
  components: { FormItemSpecificManage},
  props: {},
  data() {
    return {
        tableData: [],
        fields: [
            {
                key: "name",
                value: 'Name',
                sortable: false,
            },
            {
                key: "action",
                value: 'Action',
                sortable: false,
            }
        ],
        selectedItemSpecific: {
            id: 0,
            value: ''
        },
        pagination: {
            pageSize: 50,
            page: 1,
            count: 0
        },
        displayItemSpecificFormDrawer: false
    }
  },
  mixins: [
    common,
    listing
  ],
  methods: {
    loadPageData(params = {}) {
        let _vm = this,
            page = (typeof params.page !== 'undefined') ? params.page : _vm.pagination.page
        return GetApiActionWithAuthorization("admin/item-specific/get-page-data?pagination[pageSize]=" + _vm.pagination.pageSize + "&pagination[page]=" + page).then(res => {
            _vm.tableData = res.data
            _vm.pagination.count = res.count
        });
    },
    addData() {
        let _vm = this
        _vm.selectedItemSpecific = {
            id: 0,
            value: ''
        }
        _vm.displayItemSpecificFormDrawer = true
    },
    editData(itemSpecific) {
        let _vm = this
        _vm.selectedItemSpecific = itemSpecific
        _vm.displayItemSpecificFormDrawer = true
    },
    deleteData(id) {
        if (confirm('Are you sure that you want delete this owner ?')) {
            let _vm = this
            PostApiWithAuthorizationAction("admin/item-specific/delete/" + id).then(() => {
                _vm.tableData = _vm.tableData.filter(v=>v.id!=id)
            });
        }
    }
  },
  computed: {},
  mounted () {
    let _vm = this
    _vm.loadPageData()
  },
  watch: {
    'pagination.page': function(page) {
      this.loadPageData({ page })
    }
  }
}
</script>
<template>
    <div class="setting-item-specific__content">
        <el-drawer
            ref="productTagDrawer"
            :title="selectedItemSpecific.id == 0 ? 'Add New Item Specific' : 'Update Item Specific'"
            :visible.sync="displayItemSpecificFormDrawer"
            :close-on-press-escape="false"
            :with-header="true"
            :wrapperClosable="false"  
            :append-to-body="true"
            custom-class="common-drawer">
            <form-item-specific-manage :data="selectedItemSpecific"/>
        </el-drawer>
        <div class="my-3">
            <b-button
                variant="primary"
                @click="addData()"
            >
            <i class="fas fa-plus-circle"></i> New Item Specific
            </b-button>
        </div>
        <table
                role="table"
                aria-busy="false"
                aria-colcount="10"
                class="table b-table"
            >
                <thead
                role="rowgroup"
                class=""
                style="background-color: #74788d; color: white"
                >
                <tr role="row" class="">
                    <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    :class="[
                        field.center ? 'text-center': '',
                        'px-4'
                    ]"
                    v-for="field in fields"
                    :key="field.key"
                    >
                    <div>{{ field.value }}</div>
                    </th>
                </tr>
                </thead>
                <tbody role="rowgroup">
                <tr
                    role="row"
                    aria-rowindex="1"
                    class=""
                    v-for="data in tableData"
                    :key="data.id"
                >
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px"
                        class="col-sm">
                        {{ data.value }}
                    </td>
                    <td
                            aria-colindex="2"
                            role="cell"
                            :class="['text-center', 'px-0', 'col-sm']">
                            <div class="mb-1">
                                <b-button
                                    size="sm"
                                    variant="warning"
                                    style="margin-right: 5px"
                                    @click="editData(data)"
                                >
                                    <i class="fas fa-edit"></i> Update
                                </b-button>
                                <b-button
                                    size="sm"
                                    variant="danger"
                                    @click="deleteData(data.id)"
                                >
                                <i class="fas fa-trash-alt"></i> Delete
                                </b-button>
                            </div>
                        </td>
                </tr>
            </tbody>
        </table>
        <div class="row">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded mb-0">
                        <b-pagination
                            v-model="pagination.page"
                            :total-rows="pagination.count"
                            :per-page="pagination.pageSize"
                            ></b-pagination>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .el-color-picker--medium,
    .el-color-picker--medium .el-color-picker__trigger {
        width: 100%;
    }
    .col-sm {
        padding: 0.4em 0.75em !important;
        vertical-align: middle;
    }
</style>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
    width: 50%
}
</style>
 