<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, GetApiActionWithAuthorizationOnly, PostApiWithAuthorizationAction } from "../../../helpers/apiActions";
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  name: 'SettingPresetPage',
  components: {
    VueTagsInput,
  },
  props: {},
  data() {
    return {
      treeDisplayData: [],
      excludeItemSpecifics: [],
      loadingSection: false,
      loadingPreset: false,
      showPresetForm: false,
      enableConditionField: true,
      presetForm: {
        id: 0 ,
        name: '',
        selectedCategory: -1,
        selectedStoreCategory: [],
        presetDescriptor: '',
        presetDescriptors: [],
        itemSpecifics: {},
        fields: {},
        section: {}
      },
      categories: [
        {
          value: '-1',
          data: []
        }
      ],
      storeCategories: [],
      itemSpecifics: [],
      excludeFormSubmissionParams: {
        itemSpecifics: []
      },
      editableSections: [],
      setting: {},
      requiredItemSpecifics: [],
      expandedSections: []
    }
  },
  mixins: [
    common
  ],
  methods: {
    loadStoreCategories () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-all-store-categories?filter[tab]=sold").then(res => {
        _vm.storeCategories = res.data
      });
    },
    loadProductItemSpecifics(categoryId) {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/load-full-single-product-item-specifics/" + categoryId).then(data => {
        if (data.status == "success") {
          _vm.requiredItemSpecifics = data.requiredItemSpecifics
          _vm.itemSpecifics = data.data
          _vm.presetForm.itemSpecifics = data.data.map(i=>{
            i.formValue = (_vm.presetForm.fields.hasOwnProperty(i.value)) ? _vm.presetForm.fields[i.value] : '';
            return i;
          }).reduce((obj, itm) => Object.assign(obj, { [itm.value]: itm.formValue }), {})
        } else {
          throw new Error(data.message)
        }
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
      })
    },
    loadSections(parentId) {
      let _vm = this
      return GetApiActionWithAuthorization("admin/presets/load-sections", { parentId }).then(res => {
        if (res.data.length > 0) {
          if (parentId > 0) {
            let findIndex = _vm.treeDisplayData.findIndex(v=>v.id==parentId)
            if (findIndex > 0) {
              _vm.$set(_vm.treeDisplayData, findIndex, res.data[0])
            }
          } else {
            _vm.treeDisplayData = res.data
          }
        }
      }).finally(() => {
        _vm.loadingSection = false
      });
    },
    createSection(params = {}) {
      if (confirm('Are you sure that you want to create new section ?')) {
        let _vm = this
        PostApiWithAuthorizationAction("admin/presets/add-sections", params).then(res => {
          //let parentSection = res.parents.length > 0 ? res.parents[0] : 0
          _vm.loadSections(0).then(() => {
            _vm.expandedSections.push(res.data.parent_id)
          })
        })
      }
    },
    createPreset(params = {}) {
      //if (confirm('Are you sure that you want to create new section ?')) {
        let _vm = this
        PostApiWithAuthorizationAction("admin/presets/new", params).then(res => {
          if (res.status === "success") {
            _vm.loadPreset({
              id: 'p_' + res.data.id
            })
            _vm.loadSections(0).then(() => {
              _vm.expandedSections.push(res.data.sectionId)
            })
          }
        })
      //}
    },
    isSectionRow(data) {
      return data.id ? !data.id.toString().includes('p_') : false
    },
    deleteSection(data) {
      if (confirm('Are you sure that you want to delete this section ?')) {
        let _vm = this
        PostApiWithAuthorizationAction("admin/presets/delete-section/" + data.id).then(() => {
          _vm.loadSections(0)
        })
      }
    },
    deletePreset(data) {
      if (confirm('Are you sure that you want to delete this preset ?')) {
        let _vm = this
        PostApiWithAuthorizationAction("admin/presets/delete-preset/" + data.id.replace('p_', '')).then(() => {
          _vm.loadSections(0)
        })
      }
    },
    loadPreset(data) {
      let _vm = this
      if (!_vm.isSectionRow(data)) {//preset
        let id = data.id.replace('p_', '')
        window.history.replaceState({}, "Cybershop v2", '/settings/presets?presetId=' + id);
        _vm.showPresetForm = false
        _vm.loadingPreset = true
        window.scrollTo(0,0)
        return GetApiActionWithAuthorization("admin/presets/load-preset/" + id).then(res => {
          let fields = JSON.parse(res.data.fields != "" ? res.data.fields : "{}")
          let selectedStoreCategory = []
          let presetDescriptors= [...new Map(res.data.PresetDescriptors.map(item =>[item['descriptor'], item])).values()];
          if (fields.storeCategoryName1) {
            selectedStoreCategory.push(fields.storeCategoryName1)
          }
          if (fields.storeCategoryName2) {
            selectedStoreCategory.push(fields.storeCategoryName2)
          }
          _vm.loadCategories(Number((fields.categoryId || res.data.PresetCategories[0].categoryId) || -1))
          _vm.presetForm = Object.assign(_vm.presetForm, res.data, {
            fields,
            selectedCategory: Number((fields.categoryId || res.data.PresetCategories[0].categoryId) || -1),
            presetDescriptors: presetDescriptors.map(v=>{
              return { text: v.descriptor }
            }),
            selectedStoreCategory
          })
          _vm.loadProductItemSpecifics(_vm.presetForm.selectedCategory)
          _vm.showPresetForm = true
        }).finally(() => {
          _vm.loadingPreset = false
        });
      }
    },
      handleDragStart(node) {
        // eslint-disable-next-line
        console.log('drag start', node);
      },
      handleDragEnter(draggingNode, dropNode) {
        // eslint-disable-next-line
        console.log('tree drag enter: ', draggingNode, dropNode.label);
      },
      handleDragLeave(draggingNode, dropNode) {
        // eslint-disable-next-line
        console.log('tree drag leave: ', draggingNode, dropNode.label);
      },
      handleDragOver(draggingNode, dropNode) {
        // eslint-disable-next-line
        console.log('tree drag over: ', draggingNode, dropNode.label);
      },
      handleDragEnd(draggingNode, dropNode, dropType) {
        // eslint-disable-next-line
        console.log('tree drag end: ', draggingNode, dropNode && dropNode.label, dropType);
      },
      handleDrop(draggingNode, dropNode, dropType) {
        // eslint-disable-next-line
        console.log('tree drop: ', draggingNode, dropNode.label, dropType);
      },
      allowDrop(draggingNode, dropNode, type) {
        // eslint-disable-next-line
        console.log('allow drop: ', draggingNode, dropNode);
        if (dropNode.data.label === 'Niveau deux 3-1') {
          return type !== 'inner';
        } else {
          return true;
        }
      },
      allowDrag(draggingNode) {
        return draggingNode.data.label.indexOf('Niveau trois 3-1-1') === -1;
      },
      setProductCategory() {
        let selectedCategory = this.presetForm.selectedCategory
        this.loadProductItemSpecifics(selectedCategory)
        this.presetForm.selectedCategory = ''
        this.loadCategories(selectedCategory)
      },
      changeProductCategory(categoryId) {
        this.presetForm.selectedCategory = ''
        this.loadCategories(categoryId)
      },
      loadCategories(categoryId = null)
      {
        let _vm = this,
            params = {},
            url = "admin/ebay-services/get-build-categories";
        if (categoryId == null) {
          categoryId = _vm.presetForm.selectedCategory
        }
        //
        params.categoryId = categoryId;
        return PostApiWithAuthorizationAction(url, params).then(res => {
          if (res.status === 'success') {
            _vm.categories = res.data
            //
            if (res.children.length == 0) {
              _vm.presetForm.selectedCategory = categoryId
              _vm.submitPresetForm()
            }
            if (categoryId == -1) {
              _vm.presetForm.selectedCategory = ''
            }
          }
        })
      },
      resetCategories() {
        this.loadCategories(-1)
      },
      allowSaveItemSpecific(index) {
        let _vm = this
        _vm.itemSpecifics[index].canSelectItemSpecific = false
        _vm.excludeFormSubmissionParams.itemSpecifics.push(_vm.itemSpecifics[index].value)
      },
      saveNewItemSpecific(index) {
        let _vm = this
        let itemSpecific = _vm.itemSpecifics[index].value
        let itemValue = _vm.presetForm.itemSpecifics[itemSpecific]
        let findExcludeIndex = _vm.excludeFormSubmissionParams.itemSpecifics.findIndex(v=>v==itemSpecific)
        if (index > -1) { // only splice array when item is found
          this.$delete(_vm.excludeFormSubmissionParams.itemSpecifics, findExcludeIndex)
        }
        _vm.itemSpecifics[index].canSelectItemSpecific = true
        _vm.itemSpecifics[index].data.push({
          itemValue
        })
        //live save into db
        PostApiWithAuthorizationAction("admin/products/add-item-specific", {
          itemType: _vm.itemSpecifics[index].value,
          itemValue,
          categoryId: _vm.presetForm.selectedCategory
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
        });
        //update product item specific
        _vm.submitPresetForm()
      },
      submitPresetForm () {
        let _vm = this
        //if (_vm.allowFormSubmission) {
          let form = JSON.parse(JSON.stringify(_vm.presetForm))
          if (_vm.excludeFormSubmissionParams.itemSpecifics.length > 0) {
            for(const itemSpecific of _vm.excludeFormSubmissionParams.itemSpecifics) {
              form.itemSpecifics[itemSpecific] = undefined
            }
          }
          // submit form
          PostApiWithAuthorizationAction("admin/presets/save-preset/" + _vm.presetForm.id, form).then(res => {
            if (res.status === "success") {
              /*_vm.$toast.success("Preset information saved successfully", {
                position: "top-right",
                timeout: 1000,
                closeOnClick: true
              });*/
            }
          }).catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
        //}
      },
      editSection(data) {
        const _vm = this
        if (!_vm.editableSections.includes(data.id)) {
          _vm.editableSections.push(data.id)
          _vm.$set(_vm.presetForm.section, data.id, data.name)
          setTimeout(function() {
            document.getElementById('edit-section-input-' + data.id).focus()
          }, 200)
        } else {
          PostApiWithAuthorizationAction("admin/presets/save-section/" + data.id, {
            name: _vm.presetForm.section[data.id]
          }).then(res => {
            if (res.status === "success") {
              _vm.loadSections(0).then(() => {
                _vm.editableSections = _vm.editableSections.filter(function(item) {
                  return item != data.id
                })
              })
            }
          }).catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
        }
      },
      setTags(tags) {
        const _vm = this
        _vm.presetForm.presetDescriptors = tags
        _vm.submitPresetForm()
      },
      updateStoreCategoryFields() {
      if (!this.setting.storeCategoriesSpecificNames.includes(this.presetForm.selectedStoreCategory[0])
          && !this.setting.storeCategoriesSpecificNames.includes(this.presetForm.selectedStoreCategory[1])) {
        this.enableConditionField = true
        this.excludeItemSpecifics = ['Grade', 'Certification'];
      } else {
        this.excludeItemSpecifics = [];
        this.enableConditionField = false;
      }
    },
    setStoreCategories() {
      this.updateStoreCategoryFields()
      this.submitPresetForm()
    },
    loadPageData () {
      let _vm = this
      return GetApiActionWithAuthorizationOnly("admin/products/get-page-data?filter[tab]=sold").then(res => {
        _vm.setting = res.setting
        //_vm.users = res.users
      });
    },
    handleExpand(node) {
      let _vm = this
      _vm.expandedSections.push(node.id)
    },
    handleCollapse(node) {
      let _vm = this
      _vm.expandedSections = _vm.expandedSections.filter(id => id != node.id)
      if (node.children) {
        for(let children of node.children) {
          _vm.handleCollapse(children)
        }
      }
    }
  },
  computed: {},
  mounted () {
    const _vm = this
    if (_vm.$route.query.presetId) {
      _vm.loadPreset({
        id: 'p_' + _vm.$route.query.presetId
      })
    }
    _vm.loadingSection = true
    _vm.loadSections(0)
    _vm.loadStoreCategories()
    _vm.loadPageData()
  }
}
</script>
<template>
    <div class="setting-preset__content pt-2">
      <el-row :gutter="20">
        <el-col :span="10">
          <div class="box-section mb-3 px-0">
            <div class="box-section-header">
              MANAGE SECTIONS
              <a
                style="cursor: pointer;margin-top: -5px;color: white"
                title="Add main section"
                href="javascript:;"
                id="btn-add-parent-section"
                @click="createSection({
                  parent_id: 0
                })">
                <i class="fas fa-plus-circle"></i>
              </a>
            </div>
            <div class="box-section-body pt-0">
              <div
                class="loading-item-specific text-center"
                v-if="loadingSection"
                style="font-size: 25px">
                <i class="el-icon-loading"></i> Loading
              </div>
              <el-tree
                v-else
                :data="treeDisplayData"
                node-key="id"
                :expand-on-click-node="false"
                @node-drag-start="handleDragStart"
                @node-drag-enter="handleDragEnter"
                @node-drag-leave="handleDragLeave"
                @node-drag-over="handleDragOver"
                @node-drag-end="handleDragEnd"
                @node-drop="handleDrop"
                @node-expand="handleExpand"
                @node-collapse="handleCollapse"
                draggable
                :allow-drop="allowDrop"
                :allow-drag="allowDrag"
                :default-expanded-keys="expandedSections"
                >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span
                    v-html="node.label"
                    style="text-transform: uppercase;font-size: 16px;"
                    @click="loadPreset(data)"
                    v-if="!editableSections.includes(data.id)"></span>
                    <el-input
                      for="name"
                      class="edit-section-input"
                      :id="'edit-section-input-' + data.id"
                      v-model="presetForm.section[data.id]"
                      @keyup.native.enter="editSection(data)"
                      v-else
                      ></el-input>
                      <el-button
                        type="text"
                        size="mini"
                        title="Edit Section"
                        @click="() => editSection(data)"
                        v-if="isSectionRow(data)">
                        <i class="fas fa-pencil-alt" v-if="!editableSections.includes(data.id)"></i>
                        <i class="fas fa-save" v-else style="font-size: 16px;"></i>
                      </el-button> 
                  <span style="position: absolute; right: 0;">
                    <el-button
                      type="primary"
                      size="mini"
                      title="Add a Child Section"
                      @click="() => createSection({
                        parent_id: data.id
                      })"
                      v-if="isSectionRow(data)">
                      <i class="el-icon-plus"></i> Section
                    </el-button>
                    <el-button
                      type="success"
                      size="mini"
                      title="Add a Preset"
                      @click="() => createPreset({
                        sectionId: data.id
                      })"
                      v-if="isSectionRow(data)">
                      <i class="el-icon-plus"></i> Preset
                    </el-button>
                    <el-button
                      type="text"
                      size="mini"
                      @click="() => {
                        if (isSectionRow(data)) {
                          deleteSection(data)
                        } else {
                          deletePreset(data)
                        }
                      }">
                      <i class="el-icon-delete" style="color: red;font-size: 16px;"></i>
                    </el-button>
                  </span>
                </span>
              </el-tree>              
            </div>
          </div>
        </el-col>
        <el-col :span="14">
          <div class="box-section mb-3 px-0">
            <div class="box-section-header">
              <i class="fas fa-robot"></i> &nbsp;PRESET SETTING<span v-if="presetForm.name!=''">: {{ presetForm.name }}</span>
            </div>
            <div class="box-section-body pt-0">
              <div
                class="loading-item-specific text-center"
                v-if="loadingPreset"
                style="font-size: 25px">
                <i class="el-icon-loading"></i> Loading
              </div>
              <el-form
                class="form-horizontal pt-3 px-2"
                @submit.native.prevent="submitPresetForm"
                id="presetFormContainer"
                :model="presetForm"
                ref="presetFormContainer"
                v-if="showPresetForm">
                <b-row class="mb-2">
                  <b-col cols="9">
                    <label class="mb-0">Preset</label>
                    <el-input
                      for="name"
                      class="presetName"
                      @input="submitPresetForm()"
                      v-model="presetForm.name"
                      ></el-input>
                  </b-col>
                </b-row>
                <div class="mb-2">
                  <label class="mb-0" style="display: block">Ebay Category</label>
                  <span
                    v-for="(category, index) in categories"
                    :key="index">
                    <el-select
                      v-model="presetForm.selectedCategory"
                      @change="setProductCategory()"
                      placeholder="--- SELECT CATEGORY ---"
                      v-if="(index+1) == categories.length"
                      class="inline-dropdown"
                      size="medium"
                    >
                      <el-option
                        v-for="(option, index) in category.data"
                        :key="index"
                        :label="(option.categoryName + ((option.leafCategory == 0) ? ' >' : ''))"
                        :value="option.categoryID"
                      ></el-option>
                    </el-select>
                    <span class="mr-1" v-else>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="changeProductCategory(category.key)"
                      >
                      {{ category.value }}
                    </el-button>
                    <i class="fas fa-caret-right mx-1" style="color: red"></i>
                    </span>
                  </span>
                  <el-button
                      type="danger"
                      size="mini"
                      circle
                      icon="el-icon-close"
                      class="mx-1"
                      @click="resetCategories()"
                      >
                  </el-button>
                </div>
                <b-row>
                  <b-col cols="6">
                    <label class="mb-0"  style="display: block">Store Categories</label>
                    <el-select
                      v-model="presetForm.selectedStoreCategory"
                      @change="setStoreCategories()"
                      multiple
                      size="medium"
                      placeholder="Choose Store Category"
                      :multiple-limit="2"
                      style="width: 100%;">
                      <el-option
                      v-for="item in storeCategories"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                      </el-option>
                    </el-select>
                  </b-col>
                </b-row>
                <div class="">
                    <label
                      class="mb-0"
                      style="display: block">Preset Descriptors</label>
                    <vue-tags-input
                      v-model="presetForm.presetDescriptor"
                      :tags="presetForm.presetDescriptors"
                      :allow-duplicates="true"
                      style="max-width: 100%"
                      @tags-changed="newTags => setTags(newTags)"
                    />
                </div>
                <div class="box-section my-3">
                  <div class="box-section-header" style="width: fit-content;">ITEMS SPECIFICS</div>
                  <div class="box-section-body pt-0">
                    <b-row class="mx-0">
                    <b-col cols="3"
                      v-for="(itemSpecific, index) in itemSpecifics"
                      v-show="!excludeItemSpecifics.includes(itemSpecific.value)"
                      :key="index"
                      class="px-0">
                      <b-row class="mx-0">
                        <b-col cols="9" class="px-0">
                          <label
                            class="mb-0"
                            v-if="requiredItemSpecifics.includes(itemSpecific.value) && (presetForm.itemSpecifics[itemSpecific.value] == '' || !presetForm.itemSpecifics[itemSpecific.value])"
                            style="color: red">
                            {{ itemSpecific.value }}
                          </label>
                          <label
                            class="mb-0"
                            v-else>
                            {{ itemSpecific.value }}
                          </label>
                          <el-select
                            v-model="presetForm.itemSpecifics[itemSpecific.value]"
                            :placeholder="itemSpecific.value"
                            v-if="itemSpecific.canSelectItemSpecific"
                            @change="submitPresetForm()"
                            filterable
                            size="medium"
                            no-data-text=" "
                          >
                            <el-option
                              v-for="(data, index) in itemSpecific.data"
                              :key="index"
                              :label="data.itemValue"
                              :value="data.itemValue"
                            ></el-option>
                          </el-select>
                          <el-input
                            :placeholder="itemSpecific.value"
                            :id="'item-specific-field-' + index"
                            v-model="presetForm.itemSpecifics[itemSpecific.value]"
                            @input="submitPresetForm()"
                            v-else
                            size="medium"></el-input>
                        </b-col>
                        <b-col cols="3" class="px-1 text-center">
                          <label class="mb-0 label-field-only" style="visibility: hidden;display:block">#</label>
                          <el-button
                            type="primary"
                            v-if="itemSpecific.canSelectItemSpecific"
                            icon="fas fa-plus"
                            size="small"
                            @click="allowSaveItemSpecific(index)"
                            circle>
                          </el-button>
                          <el-button
                            type="warning"
                            v-else
                            icon="fas fa-save"
                            @click="saveNewItemSpecific(index)"
                            size="small"
                            circle>
                          </el-button>
                        </b-col>
                      </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <!--div class="text-right">
                  <el-button
                    type="warning"
                    size="small"
                    native-type="submit"
                    >Update
                  </el-button>
                </div-->
              </el-form>        
            </div>
          </div>       
        </el-col>
      </el-row>
    </div>
</template>
<style>
  .box-section-header {
    width: 100%;
    padding: 5px;
    font-size: 18px;
  }
  #btn-add-parent-section {
    float: right;
    margin-top: 0 !important;
  }
  .box-section {
    border: 2px solid #1b98d6;
  }
  ol, ul {
    padding-left: 0;
    font-size: 16px;
  }
  div.row_data {
    height: 25px !important;
  }
  #preset-treeview ul li:nth-child(odd) div {
    background-color: #ddd !important;
    padding-left: 5px;
  }
  #preset-treeview ul li:nth-child(even) div {
    background-color: #fff !important;
    padding-left: 5px;
  }
  #preset-treeview ul:first-child {
    max-height: 100% !important;
    width: 100% !important;
    overflow: hidden !important;
  }
  .selected_node {
    font-weight: bold !important;
  }
  .node .row_data span.capitalize {
    cursor: pointer;
  }
  .add_node_btn {
    font-size: 14px;
  }
  .el-tree-node {
    white-space: nowrap;
    outline: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .ti-tag {
    background-color: #409EFF !important;
  }
  /*.el-tree-node.is-current .el-tree-node__content:first-child{
    background-color: #ddd !important;
  }*/
  .edit-section-input .el-input__inner{
    height: 28px !important;
  }
</style>