<script>
// import {
//   PostApiWithAuthorizationAction
// } from "@/helpers/apiActions";
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';
import moment from 'moment-timezone';

export default {
  name: 'SettingGeneralPage',
  components: {},
  props: {
    settingModel: {
      type: Object,
      required: true
    },
  },
  data() {
    // eslint-disable-next-line
    var validateCategories = (rule, value, callback) => {
        if (value && this.form.categories.length == 0) {
            callback(new Error(' '));
        }
        callback();
    };
    // eslint-disable-next-line
    var validateStoreCategories = (rule, value, callback) => {
        if (value && this.form.storeCategoriesSpecifics.length == 0) {
            callback(new Error(' '));
        }
        callback();
    };
    return {
      form: {
        maxCharacterCount: '',
        maxBarcodeCount: '',
        tierNumberLimit: '',
        maxIdentifiedSubmitLimitPerDay: '',
        categories: [],
        storeCategoriesSpecifics: [],
        time_format: 1,
        scheduler_times: []
      },
      timeFormats: [
        { value: 1, text: 'AM/PM' },
        { value: 2, text: '24 hour' }
      ],
      categories: [],
      storeCategories: [],
      schedulerTimes: [],
      rules: {
        maxCharacterCount: [{ required: true, message: ' ', trigger: 'blur' }],
        maxBarcodeCount: [{ required: true, message: ' ', trigger: 'blur' }],
        tierNumberLimit: [{ required: true, message: ' ', trigger: 'blur' }],
        maxIdentifiedSubmitLimitPerDay: [{ required: true, message: ' ', trigger: 'blur' }],
        categories: [
            { validator: validateCategories, trigger: 'blur', message: ' ' }
        ],
        storeCategoriesSpecifics: [
            { validator: validateStoreCategories, trigger: 'blur', message: ' ' }
        ]
      },
      isFormSubmitting: false
    }
  },
  mixins: [
    common
  ],
  methods: {
    formatTime(time) {
        return time.map(v=>moment('1973-01-01 ' + v.time).format('HH:mm'))
    },
    onSubmit() {
        let _vm = this
        _vm.$refs.formGeneral.validate((valid) => {
            if (valid) {
                _vm.isFormSubmitting = true
                PostApiWithAuthorizationAction("admin/setting/general/save-page-data", _vm.form).then(() => {
                    _vm.$toast.success("Changes saved successfully.", {
                        position: "top-right",
                        timeout: 1000,
                        closeOnClick: true
                    });
                    _vm.isFormSubmitting = false
                });
            }
        });
    },
    loadPageData() {
        let _vm = this
        return GetApiActionWithAuthorization("admin/setting/general/get-page-data").then(res => {
            _vm.categories = res.data.categories
            _vm.storeCategories = res.data.storeCategories
            _vm.form = {
                ... _vm.settingModel,
                categories: _vm.settingModel.categories.split('||').map(v=>parseInt(v)),
                storeCategoriesSpecifics: _vm.settingModel.storeCategoriesSpecificNames.filter((v,i,a)=>a.indexOf(v)==i),
                scheduler_times: _vm.formatTime(res.data.schedulerTimes.filter(v=>v.status==1))
            }
            _vm.schedulerTimes = _vm.formatTime(res.data.schedulerTimes)
        });
    }
  },
  computed: {},
  mounted () {
    let _vm = this
    _vm.loadPageData().then(() => {
        _vm.$root.$emit('display-page')
    })
  },
  watch: {}
}
</script>
<template>
    <div class="setting-general__content">
        <b-row>
            <b-col cols="4">
                <el-form :model="form" @submit.prevent.native="onSubmit" ref="formGeneral">
                    <label class="mb-0">Title Character Limit</label>
                    <el-form-item
                        class="mb-2"
                        :rules="rules.maxCharacterCount"
                        prop="maxCharacterCount">
                        <el-input
                            v-model="form.maxCharacterCount"
                            type="number"
                            placeholder="Max Auction Item Title length"
                            ></el-input>
                            <small tabindex="-1" class="form-text text-muted mt-0">Set Limit length for auction item title.</small>
                    </el-form-item>
                    <label class="mb-0">Barcode Character Limit</label>
                    <el-form-item
                        class="mb-2"
                        :rules="rules.maxBarcodeCount"
                        prop="maxBarcodeCount">
                        <el-input
                            v-model="form.maxBarcodeCount"
                            type="number"
                            placeholder="Max Barcode character count"
                            ></el-input>
                            <small tabindex="-1" class="form-text text-muted mt-0">Set Limit length for the barcode.</small>
                    </el-form-item>
                    <label class="mb-0">Presets Tier Number Limit</label>
                    <el-form-item
                        class="mb-2"
                        :rules="rules.tierNumberLimit"
                        prop="tierNumberLimit">
                        <el-input
                            v-model="form.tierNumberLimit"
                            type="number"
                            placeholder="Max Tier Number Limit"
                            ></el-input>
                            <small tabindex="-1" class="form-text text-muted mt-0">Set number of levels to load presets.</small>
                    </el-form-item>
                    <label class="mb-0">Categories</label>
                    <el-form-item
                        class="mb-2"
                        :rules="rules.categories"
                        prop="categories">
                        <el-select
                            v-model="form.categories"
                            multiple
                            size="medium"
                            style="display: block"
                            placeholder="Choose Ebay Categories">
                            <el-option
                                v-for="item in categories"
                                :key="item.id"
                                :label="item.categoryName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <label class="mb-0">Store Categories which require item specifics</label>
                    <el-form-item
                        class="mb-2"
                        :rules="rules.storeCategoriesSpecifics"
                        prop="storeCategoriesSpecifics">
                        <el-select
                            v-model="form.storeCategoriesSpecifics"
                            multiple
                            size="medium"
                            style="display: block"
                            placeholder="Choose Ebay Store Category">
                            <el-option
                                v-for="item in storeCategories"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                        <small tabindex="-1" class="form-text text-muted mt-0">Item Specific could be Certification, Grade.</small>
                    </el-form-item>
                    <label class="mb-0">Time format</label>
                    <el-form-item class="mb-2">
                        <el-select
                            v-model="form.time_format"
                            size="medium"
                            style="display: block"
                            placeholder="Choose Time Format">
                            <el-option
                                v-for="item in timeFormats"
                                :key="item.value"
                                :label="item.text"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <label class="mb-0">Scheduler Times</label>
                    <el-form-item class="mb-2">
                        <el-select
                            v-model="form.scheduler_times"
                            size="medium"
                            style="display: block"
                            placeholder="Choose Times"
                            multiple>
                            <el-option
                                v-for="item in schedulerTimes"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <label class="mb-0">Max Identified Daily Submit</label>
                    <el-form-item
                        class="mb-2"
                        :rules="rules.maxIdentifiedSubmitLimitPerDay"
                        prop="maxIdentifiedSubmitLimitPerDay">
                        <el-input
                            v-model="form.maxIdentifiedSubmitLimitPerDay"
                            type="number"
                            placeholder="Max Identified Daily Submit"
                            ></el-input>
                            <small tabindex="-1" class="form-text text-muted mt-0">Set how many items will be submitted per day from identified.</small>
                    </el-form-item>
                    <div class="">
                        <b-button type="submit" variant="primary" v-if="!isFormSubmitting">
                            Update
                        </b-button>
                        <b-button type="button" variant="primary" v-else disabled>
                            <i class="el-icon-loading"></i> Processing
                        </b-button>
                    </div>
                </el-form>
            </b-col>
        </b-row>
    </div>
</template>
<style>
.el-form-item__content {
    line-height: 18px;
}
</style>