import { render, staticRenderFns } from "./ipWhitelist.vue?vue&type=template&id=59ed0bac&scoped=true&"
import script from "./ipWhitelist.vue?vue&type=script&lang=js&"
export * from "./ipWhitelist.vue?vue&type=script&lang=js&"
import style0 from "./ipWhitelist.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ipWhitelist.vue?vue&type=style&index=1&id=59ed0bac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ed0bac",
  null
  
)

export default component.exports