<script>
import {  common } from '@/mixins/common';
import { PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'FormSocialMediaManage',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    },
    socialMediasPlateforms: {
        type: Array,
        required: true
    }
  },
  data() {
    return {
        form: {
            id: 0,
            platform: 'Facebook',
            name: '',
            pageId: '',
            pageUrl: '',
            clientID: '',
            clientSecret: '',
            tempToken: ''
        },
        rules: {
            name: [
                { required: true, message: ' ', trigger: 'change' }
            ],
            pageId: [
                { required: true, message: ' ', trigger: 'change' }
            ],
            pageUrl: [
                { required: true, message: ' ', trigger: 'change' }
            ],
            clientID: [
                { required: true, message: ' ', trigger: 'change' }
            ],
            clientSecret: [
                { required: true, message: ' ', trigger: 'change' }
            ],
            tempToken: [
                { required: true, message: ' ', trigger: 'change' }
            ]
        }
    }
  },
  mixins: [
    common
  ],
  methods: {
    submitForm () {
        let _vm = this
        this.$refs.formSocialMediaManager.validate((valid) => {
            if (valid) {
                PostApiWithAuthorizationAction("admin/social-medias/save-data/", _vm.form).then(() => {
                    _vm.$root.$emit('load-social-medias')
                });
            } else {
                return false;
            }
        });
    },
    setPlateform(platform) {
      this.$set(this.form, 'platform', platform);
    }
  },
  computed: {
    isFacebookPlatform () {
        return (this.form.platform.toLowerCase()=='facebook')
    }
  },
  mounted () {
    this.form = {
        ... this.data
    }
  },
  watch: {
    data: function(newValue) {
        let _vm = this
        // eslint-disable-next-line
        console.log(newValue)
        _vm.form = {
            ... newValue
        }
    }
  }
}
</script>
<template>
    <div class="demo-drawer__content">
        <el-form
            :model="form"
            ref="formSocialMediaManager"
            @submit.native.prevent="submitForm">
            <b-row class="my-3 mx-3">
                <b-col cols="4" style="padding-left: 0;text-transform: capitalize;">
                    <el-select
                        v-model="form.platform"
                        placeholder=""
                        @change="changePlateform()">
                        <el-option
                            v-for="(platform, index) in socialMediasPlateforms"
                            :key="index"
                            :label="capitalize(platform.name)"
                            :value="platform.name">
                        </el-option>
                    </el-select>
                </b-col>
                <b-col cols="8" class="px-0">
                    <el-form-item
                        class="mb-0"
                        :rules="rules.name"
                        prop="name"
                        label="">
                        <el-input
                            placeholder="Social Media Name"
                            v-model="form.name"
                            class=""></el-input>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mx-0 text-right">
                <b-col cols="12">
                    <div class="box-section mb-3 pb-1" v-if="isFacebookPlatform">
                        <div class="box-section-header">PAGE FIELDS</div>
                        <div class="box-section-body py-0">
                            <b-row class="my-2 mx-0">
                                <b-col cols="6" style="padding-left: 0;">
                                    <el-form-item
                                        class="mb-0"
                                        :rules="rules.pageId"
                                        prop="pageId"
                                        label="">
                                        <el-input placeholder="" v-model="form.pageId">
                                            <template slot="prepend">ID</template>
                                        </el-input>
                                    </el-form-item>
                                </b-col>
                                <b-col cols="6" class="px-0">
                                    <el-form-item
                                        class="mb-0"
                                        :rules="rules.pageUrl"
                                        prop="pageUrl"
                                        label="">
                                        <el-input placeholder="" v-model="form.pageUrl">
                                            <template slot="prepend">URL</template>
                                        </el-input>
                                    </el-form-item>
                                </b-col>
                            </b-row>
                            <div class="mb-2">
                                <el-form-item
                                    class="mb-0"
                                    :rules="rules.clientID"
                                    prop="clientID"
                                    label="">
                                    <el-input placeholder="" v-model="form.clientID">
                                        <template slot="prepend">Client ID</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="mb-2">
                                <el-form-item
                                    class="mb-0"
                                    :rules="rules.clientSecret"
                                    prop="clientSecret"
                                    label="">
                                    <el-input placeholder="" v-model="form.clientSecret">
                                        <template slot="prepend">Client Secret</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="mb-2">
                                <el-form-item
                                    class="mb-0"
                                    :rules="rules.clientSecret"
                                    prop="clientSecret"
                                    label="">
                                    <el-input placeholder="" v-model="form.tempToken">
                                        <template slot="prepend">Init Token</template>
                                        <template slot="append">
                                            <a href="https://developers.facebook.com/tools/explorer/" target="_blank">
                                                <el-button type="primary" icon="el-icon-refresh" circle></el-button>
                                            </a>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div
                                style="color: red;font-weight: 600;font-style: italic;"
                                class="mt-1 text-left">It will be used to generate User &amp; Page Access Token</div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mx-0 text-right">
                <b-col cols="12">
                    <el-button
                        type="success"
                        native-type="submit">
                        <i class="fas fa-save"></i> Save Changes
                    </el-button>
                </b-col>
            </b-row>
        </el-form>
    </div>
</template>