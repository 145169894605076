<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';
import FormSocialMediaManage from '../../drawers/setting/FormSocialMediaManage.vue';
import { isMobile, isTablet } from 'mobile-device-detect';

export default {
  name: 'SettingSocialMediaPage',
  components: { FormSocialMediaManage },
  props: {},
  data() {
    return {
        tableData: [],
        socialMediasPlateforms: [],
        fields: [
            {
                key: "name",
                value: 'Name',
                sortable: false,
            },
            {
                key: "actions",
                value: 'Actions',
                sortable: false,
                center: true
            }
        ],
        selectedItem: {},
        displaySMDrawer: false
    }
  },
  mixins: [
    common
  ],
  methods: {
    loadPlateforms () {
        let _vm = this
        return GetApiActionWithAuthorization("admin/social-medias/get-platforms").then(res => {
            _vm.socialMediasPlateforms = res.data
        });
    },
    loadPageData() {
        let _vm = this
        return GetApiActionWithAuthorization("admin/social-medias/get-page-data").then(res => {
            _vm.tableData = res.data
        });
    },
    addData() {
        let _vm = this
        _vm.selectedItem = {
            id: 0,
            platform: 'Facebook',
            socialMediaPlatformId: 1,
            name: '',
            pageId: '',
            pageUrl: '',
            clientID: '',
            clientSecret: '',
            tempToken: ''
        }
        _vm.displaySMDrawer = true
    },
    editData(itemSpecific) {
        let _vm = this
        const platform = _vm.socialMediasPlateforms.find(v=>v.id==itemSpecific.SocialMediaPlatformId)
        _vm.selectedItem = {
            ... itemSpecific,
            platform: platform.name
        }
        _vm.displaySMDrawer = true
    },
    generateToken(id) {
        let _vm = this,
            item = _vm.tableData[id];
        _vm.tableData.splice(id, 1, {
            ... _vm.tableData[id],
            disableTokenBtn: true
        })
        return GetApiActionWithAuthorization("admin/social-medias/generate-token/" + item.id).then(res => {
            _vm.loadPageData()
            if (res.status === 'success') {
                _vm.$toast.success(res.message, {
                    position: "top-right",
                    timeout: 1000,
                    closeOnClick: true
                });
            } else {
                _vm.$toast.warning(res.message, {
                    position: "top-right",
                    timeout: 1000,
                    closeOnClick: true
                });
            }
        }).finally(() => {
            _vm.tableData[id].disableTokenBtn = false
        });
    },
    deleteData(id) {
        if (confirm('Are you sure that you want delete this owner ?')) {
            let _vm = this
            PostApiWithAuthorizationAction("admin/social-medias/delete/" + id).then(() => {
                _vm.tableData = _vm.tableData.filter(v=>v.id!=id)
            });
        }
    }
  },
  computed: {
    drawerSize () {
      return isMobile ? '100%' : isTablet ? '80%' : '40%'
    },
  },
  mounted () {
    let _vm = this
    _vm.loadPageData()
    _vm.loadPlateforms()
    _vm.$root.$on('load-social-medias', () => {
      _vm.loadPageData()
      _vm.displaySMDrawer = false
    })
  }
}
</script>
<template>
    <div class="setting-item-specific__content">
        <el-drawer
            ref="productTagDrawer"
            title="Social Media"
            :visible.sync="displaySMDrawer"
            :close-on-press-escape="false"
            :with-header="true"
            :wrapperClosable="false"  
            :append-to-body="true"
            :size="drawerSize"
            custom-class="common-drawer">
            <form-social-media-manage :data="selectedItem" :socialMediasPlateforms="socialMediasPlateforms"/>
        </el-drawer>
        <div class="my-3">
            <b-button
                variant="primary"
                @click="addData()"
            >
            <i class="fas fa-plus-circle"></i> New Social Media
            </b-button>
        </div>
        <div class="table-responsive">
            <table
                role="table"
                aria-busy="false"
                aria-colcount="10"
                class="table b-table"
                >
                    <thead
                    role="rowgroup"
                    class=""
                    style="background-color: #74788d; color: white"
                    >
                    <tr role="row" class="">
                        <th
                        role="columnheader"
                        scope="col"
                        aria-colindex="1"
                        :class="[
                            field.center ? 'text-center': '',
                            'px-4'
                        ]"
                        v-for="field in fields"
                        :key="field.key"
                        >
                        <div>{{ field.value }}</div>
                        </th>
                    </tr>
                    </thead>
                    <tbody role="rowgroup">
                    <tr
                        role="row"
                        aria-rowindex="1"
                        class=""
                        v-for="(data, index) in tableData"
                        :key="data.id"
                    >
                        <td
                            aria-colindex="1"
                            role="cell"
                            style="font-size: 14px"
                            class="col-sm">
                            {{ data.name }}
                        </td>
                        <td
                            aria-colindex="2"
                            role="cell"
                            :class="['text-center', 'px-0', 'col-sm']">
                            <div class="mb-1">
                                <a :href="data.pageUrl"
                                    class="btn btn-primary btn-sm mb-1"
                                    style="margin-right: 5px"
                                    target="_blank">
                                    <i class="fas fa-eye"></i> View Page
                                </a>
                                <b-button
                                    size="sm"
                                    variant="success"
                                    style="margin-right: 5px"
                                    class="mb-1"
                                    @click="generateToken(index)"
                                    :disabled="data.disableTokenBtn"
                                >
                                    <i class="fas fa-sync"></i> Generate Token
                                </b-button>
                                <b-button
                                    size="sm"
                                    class="mb-1"
                                    variant="warning"
                                    style="margin-right: 5px"
                                    @click="editData(data)"
                                >
                                    <i class="fas fa-edit"></i> Update
                                </b-button>
                                <b-button
                                    size="sm"
                                    class="mb-1"
                                    variant="danger"
                                    @click="deleteData(data.id)"
                                >
                                <i class="fas fa-trash-alt"></i> Delete
                                </b-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<style>
    .el-color-picker--medium,
    .el-color-picker--medium .el-color-picker__trigger {
        width: 100%;
    }
    .col-sm {
        padding: 0.4em 0.75em !important;
        vertical-align: middle;
    }
</style>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
@media (min-width: 768px) {
    .b-table {
        width: 50%;
    }
}
@media (min-width: 1025px) and (max-width: 1280px) {
    .b-table {
        width: 75%;
    }
}
</style>
 