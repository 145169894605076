<script>
import {  common } from '@/mixins/common';
import { PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'FormIpWhitelistManage',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    var validateIPaddress = (rule, value, callback) => {
        const _vm = this
        if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(_vm.form.ip))
        {
            callback(new Error(' '));
        }
        callback();
    }
    //
    return {
        form: {
            id: 0,
            ip: '',
            commnt: ''
        },
        rules: {
            ip: [
                { required: true, message: ' ', trigger: 'change' },
                { validator: validateIPaddress, trigger: 'change' }
            ]
        }
    }
  },
  mixins: [
    common
  ],
  methods: {
    submitForm () {
        let _vm = this
        this.$refs.formOwnerManager.validate((valid) => {
            // eslint-disable-next-line
            console.log(valid)
            if (valid) {
                PostApiWithAuthorizationAction("admin/ip-whitelist/save-data/", _vm.form).then(() => {
                    location.reload()
                });
            }
        });
    }
  },
  computed: {},
  mounted () {
    this.form = {
        ... this.data
    }
  },
  watch: {
    data: function(newValue) {
        let _vm = this
        _vm.form = {
            ... newValue
        }
        //
        if (_vm.$refs.drawerPrimaryField) {
          _vm.$refs.drawerPrimaryField.focus()
        }
    }
  }
}
</script>
<template>
    <div class="demo-drawer__content">
        <el-form
            :model="form"
            ref="formOwnerManager"
            @submit.native.prevent="submitForm">
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <el-form-item
                        class="mb-0"
                        :rules="rules.ip"
                        prop="ip"
                        label="IP Address">
                        <el-input
                            v-model="form.ip"
                            placeholder="1.1.1.1"
                            ref="drawerPrimaryField"
                            ></el-input>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mb-4 mx-0">
                <b-col cols="12">
                    <el-form-item class="mb-0" label="Comment">
                        <el-input
                            v-model="form.comment"
                            type="textarea"
                            placeholder="Comment"
                            :rows="4"
                            ></el-input>
                    </el-form-item>
                </b-col>
            </b-row>
            <b-row class="mx-0 text-right">
                <b-col cols="12">
                    <el-button
                        type="success"
                        native-type="submit">
                        <i class="fas fa-save"></i> Save Changes
                    </el-button>
                </b-col>
            </b-row>
        </el-form>
    </div>
</template>

<style>
    .el-form-item__label {
        line-height: 14px;
    }
</style>